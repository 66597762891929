@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;
      /* Firefox */
    }
  }
}

.toastBody {
  font-family: "Prompt", sans-serif;
  color: #000;
}

.toastProgress {
  background: #000000 !important;
}

.toastBodyDark {
  font-family: "Prompt", sans-serif;
  color: #fff;
}

.toastProgressDark {
  background: #fff !important;
}

.textarea-container {
  position: relative;
}

.textarea {
  resize: none;
  overflow-y: hidden;
}

.textarea::-webkit-scrollbar {
  width: 0;
  background: transparent;
}